<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
import {Day7TraDitionQACount} from '../../../../libs/axios';
export default {
  data () {
    return {
      cdata:"",
    }
  },
  methods: {
     init() {
      this.getDay7WageEchartsList();
    },
   getDay7WageEchartsList() {
           var params = window.location.href.split('?');
      var workId = params[1].split('&')[0].split("=")[1];
      this.loading = true;
      Day7TraDitionQACount(workId,4).then((res) => {
        // vm.$store.commit("setMessageType", res.result);
        //  alert(JSON.stringify(res.data));
        this.loading = false;
          this.cdata=res.data;
      });
    },
  },
  components: {
    Chart,
  },
  mounted () {
    this.init();
    setInterval(this.init,120000);
  },
}
</script>

<style lang="scss" scoped>
</style>