<template>
  <div id="bottomLeftView">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-l text mx-2" style="    font-size: smaller;">工序产量</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="height:4.28rem;font-size: 0.7rem;color:#ffc103" />
      </div>
    </div>
  </div>
</template>

<script>
import {selectWageSeqNumList} from '../../../libs/axios';
export default {
  data() {
    return {
      config: {
        header: ["订单编码","工序名称","累计产量", "今日产量"],
        data:[],
        rowNum: 5, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [80,180,430,180,180],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
     setInterval(this.init,120000);
  },
  methods: {
    init() {
      this.getWageList();
    },
     getWageList() {
       
      this.loading = true;
      var params = window.location.href.split('?');
      var workId = params[1].split('&')[0].split("=")[1];
      selectWageSeqNumList(workId).then((res) => {
        // vm.$store.commit("setMessageType", res.result);
          // alert(JSON.stringify(res.data));
          
          this.loading = false;
          this.$set(this.config,  this.config.data,res.data);
          this.config.data=res.data;
      });
    },
  }
};
</script>

<style lang="scss">
#bottomLeftView {
  padding: 0.2rem;
  height: 5.525rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  font-size: 0.7rem;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
    font-size:0.5rem;
  }
  .text {
    font-size: 0.5rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.5rem;
  }
  .header-item {
    font-size: 0.4rem;
    text-align: center;
  }
  .row-item {
    font-size: 0.4rem;
    color: "#ffc103";
  }
  .ceil {
    font-size: 0.5rem;
    color: #03ebfc;
    text-align: center;
  }
}
</style>